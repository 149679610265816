export const enableBodyScroll = () => {

	const body = document.body;
	const html = document.documentElement;
	const scrollY = body.style.top;

	if (document.body.scrollHeight > window.innerHeight) {
		html.style.overflowY = '';
	}

	body.style.position = '';
	body.style.width = '';
	body.style.top = '';

	window.scrollTo(0, parseInt(scrollY || '0') * -1);

};

export const disableBodyScroll = () => {

	const body = document.body;
	const html = document.documentElement;
	const scrollY = html.getBoundingClientRect().top;

	if (document.body.scrollHeight > window.innerHeight) {
		html.style.overflowY = 'scroll';
	}

	body.style.position = 'fixed';
	body.style.width = '100%';

	body.style.top = `${scrollY}px`;

};

export const isValidEmail = (email: string) => {

	let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return pattern.test(String(email).toLowerCase());

};

export const isValidMinLength = (value: string, min: number) => value.length >= min;

export const isValidMaxLength = (value: string, max: number) => value.length <= max;