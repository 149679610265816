const routes = {
    home: '/',
    contacts: '/contacts',
    cookiePolicy: '/cookie-policy',
    privacyPolicy: '/privacy-policy',
    ourProcess: '/our-process',
    faq: '/faq',
    manualTesting: '/services/manual-testing',
    automationTesting: '/services/automation-testing',
    apiTesting: '/services/api-testing',
    webAndMobileQA: '/services/web-and-mobile-qa',
    securityTesting: '/services/security-testing',
    performanceTesting: '/services/performance-testing'
};

export default routes;