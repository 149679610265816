import {lazy, useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router';
import classNames from 'classnames';
import constants from 'assets/sass/constants.module.scss';
import {enableBodyScroll, disableBodyScroll} from 'services/utils';
import routes from 'config/routes';

const MobileNav = lazy(() => import('components/common/MobileNav'));
const LoginDialog = lazy(() => import('components/common/LoginDialog'));

const Header = () => {

	const location = useLocation();

	useEffect(() => {
		setIsVisibleLoginDialog(false);
	}, [location]);

	const [isVisibleMobileNav, setIsVisibleMobileNav] = useState(false);
	const [isVisibleLoginDialog, setIsVisibleLoginDialog] = useState(false);

	const toggleMobileNav = () => {

		if (isVisibleMobileNav) {
			enableBodyScroll();
			setIsVisibleMobileNav(false);
		} else {
			disableBodyScroll();
			setIsVisibleMobileNav(true);
		}
	};

	const toggleLoginDialog = () => setIsVisibleLoginDialog((prevState: any) => !prevState);
	
	const homeLinkClasses = classNames('link-underline', {
        'is-active': location.pathname === routes.home
    });

	const ourProcessClasses = classNames('link-underline', {
        'is-active': location.pathname === routes.ourProcess
    });

	const faqClasses = classNames('link-underline', {
        'is-active': location.pathname === routes.faq
    });

	const contactsClasses = classNames('link-underline', {
        'is-active': location.pathname === routes.contacts
    });

	return (
		<Fragment>
			
			{isVisibleMobileNav && (
				<MobileNav
					closeNav={toggleMobileNav}
				/>
			)}
			{isVisibleLoginDialog && (
				<LoginDialog
					closeDialog={toggleLoginDialog}
				/>
			)}
			<header className='Header'>
				<div className="container">
					<Link to={routes.home}>
						<img 
							src="/images/logos/logo.svg" 
							alt="logo" 
							className='logo'
							width="140"
							height="39"
						/>
					</Link>
					<nav className='navigation is-hidden-touch'>
						<ul>
							<li>
								<Link 
									to={routes.home} 
									className={homeLinkClasses}
								>
									Home
								</Link>
							</li>
							<li className='services'>
								<div className='link-underline'>
									Services
								</div>
								<div className="dropdown-wrapper">
									<ul className="dropdown">
										<li>
											<Link to={routes.manualTesting}>Manual testing</Link>
										</li>
										<li>
											<Link to={routes.automationTesting}>Automation testing</Link>
										</li>
										<li>
											<Link to={routes.apiTesting}>API Testing</Link>
										</li>
										<li>
											<Link to={routes.webAndMobileQA}>Web and Mobile QA</Link>
										</li>
										<li>
											<Link to={routes.securityTesting}>Security Testing</Link>
										</li>
										<li>
											<Link to={routes.performanceTesting}>Performance Testing</Link>
										</li>
									</ul>
								</div>
							</li>
							<li>
								<Link 
									to={routes.ourProcess} 
									className={ourProcessClasses}
								>
									Our process
								</Link>
							</li>
							<li>
								<Link 
									to={`${routes.home}#solutions`} 
									className='link-underline'
								>
									Solutions
								</Link>
							</li>
							<li>
								<Link 
									to={routes.faq} 
									className={faqClasses}
								>
									FAQ
								</Link>
							</li>
							<li>
								<Link 
									to={routes.contacts} 
									className={contactsClasses}
								>
									Contacts
								</Link>
							</li>
						</ul>
					</nav>

					<div className="right-column is-hidden-touch">
						<img 
							src="/images/icons/portrait.svg" 
							alt="profile"
							width="24"
							height="24"
							className='has-cursor-pointer'
							onClick={toggleLoginDialog}
						/>
					</div>

					<div className='is-hidden-desktop is-flex align-center'>
						<img 
							src="/images/icons/portrait.svg" 
							alt="profile"
							width="36"
							height="36"
							className='m-r-1'
							onClick={toggleLoginDialog}
						/>
						<img 
							src="/images/icons/burger.svg" 
							alt="burger"
							width="36"
							height="28"
							onClick={toggleMobileNav}
						/>
					</div>
				</div>
			</header>
			<style jsx global> {`
				.Header {
					height: 3.5rem;
					padding: 0.5rem 0;
					position: fixed;
					top: 0;
					left: 0;
					right: 0;
					z-index: 1000;
					backdrop-filter: saturate(50%) blur(5px);
					-webkit-backdrop-filter: saturate(50%) blur(5px);
					background-color: rgba(255, 255, 255, 0.75);
					display: flex;
					@media all and (max-width: ${constants.tabletMaxW}) {
						padding: 0.5rem 1rem;
						.container {
							margin: 0;
							width: 100% !important;
						}
					}
					.container {
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex-grow: 0;
						width: calc(100% - 2rem);
					}
					.navigation {
						ul {
							display: flex;
							gap: 2rem;
							.link-underline {
								font-family: ${constants.familySecondary};
								font-size: 1.125rem;
							}
						}
					}
					.services {
						position: relative;
						&:hover {
							.dropdown-wrapper {
								opacity: 1;
								visibility: visible;
								transform: translate(-50%, 0) !important;
							}
						}
						.dropdown-wrapper {
							opacity: 0;
							visibility: hidden;
							position: absolute;
							left: 50%;
							transform: translate(-50%, -30px) !important;
							transition: 0.3s ease;
							animation-fill-mode: forwards;
							padding-top: 1rem;
							&:before {
								content: '';
								position: absolute;
								top: 10px;
								left: 50%;
								transform: translateX(-50%) rotate(-45deg);
								width: 0;
								height: 0;
								box-shadow: 0px 1px 6px #00000029;
								border-top: 6px solid ${constants.white};
								border-right: 6px solid ${constants.white};
								border-bottom: 6px solid transparent;
								border-left: 6px solid transparent; 
								z-index: -1;
							}
							.dropdown {
								background-color: ${constants.white};
								border-radius: ${constants.radius};
								box-shadow: 0px 3px 6px #00000029;
								display: block;
								display: block;
								padding: 0.5rem;
								li {
									padding: 0.25rem 0.5rem;
									white-space: nowrap;
									a {
										font-size: 1rem;
										font-family: ${constants.familySecondary};
										&:hover {
											color: ${constants.blue};

										}
									}
								}
							}
						}
					}
				}
			`}</style>
		</Fragment>
	);
};

export default Header;