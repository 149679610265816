import {lazy, Suspense, useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import {parseCookies} from 'nookies';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import CookieToast from 'components/common/CookieToast';
import routes from 'config/routes';

const Home = lazy(() => import('components/pages/Home/Home'));
const Contacts = lazy(() => import('components/pages/Contacts/Contacts'));
const CookiePolicy = lazy(() => import('components/pages/CookiePolicy/CookiePolicy'));
const PrivacyPolicy = lazy(() => import('components/pages/PrivacyPolicy/PrivacyPolicy'));
const FAQ = lazy(() => import('components/pages/FAQ/FAQ'));
const OurProcess = lazy(() => import('components/pages/OurProcess/OurProcess'));

const APITesting = lazy(() => import('components/pages/Services/APITesting/APITesting'));
const AutomationTesting = lazy(() => import('components/pages/Services/AutomationTesting/AutomationTesting'));
const ManualTesting = lazy(() => import('components/pages/Services/ManualTesting/ManualTesting'));
const PerformanceTesting = lazy(() => import('components/pages/Services/PerformanceTesting/PerformanceTesting'));
const SecurityTesting = lazy(() => import('components/pages/Services/SecurityTesting/SecurityTesting'));
const WebAndMobileQA = lazy(() => import('components/pages/Services/WebAndMobileQA/WebAndMobileQA'));

const App = () => {

    const [isVisibleCookiesToast, setIsVisibleCookiesToast] = useState<boolean>(false);

    useEffect(() => {

        const {consent} = parseCookies();
        let timeout: any;
        if (!consent) {

            timeout = setTimeout(() => {
                setIsVisibleCookiesToast(true);
            }, 3000);
        }
        
        return () => clearTimeout(timeout);

    }, []);

    const closeCookiesToast = () => setIsVisibleCookiesToast(false);

    return (
        
        <Suspense fallback={null}>
            <Header/>
            <Routes>
                <Route path={routes.home} element={<Home/>}/>
                <Route path={routes.contacts} element={<Contacts/>}/>
                <Route path={routes.cookiePolicy} element={<CookiePolicy/>}/>
                <Route path={routes.privacyPolicy} element={<PrivacyPolicy/>}/>
                <Route path={routes.faq} element={<FAQ/>}/>
                <Route path={routes.ourProcess} element={<OurProcess/>}/>
                <Route path={routes.apiTesting} element={<APITesting/>}/>
                <Route path={routes.automationTesting} element={<AutomationTesting/>}/>
                <Route path={routes.manualTesting} element={<ManualTesting/>}/>
                <Route path={routes.performanceTesting} element={<PerformanceTesting/>}/>
                <Route path={routes.securityTesting} element={<SecurityTesting/>}/>
                <Route path={routes.webAndMobileQA} element={<WebAndMobileQA/>}/>
            </Routes>
            <Footer/>

            {isVisibleCookiesToast && (
                <CookieToast
                    closeCookiesToast={closeCookiesToast}
                />
            )}
        </Suspense>

    )
};

export default App;