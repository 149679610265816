import {useState, useEffect, Fragment, lazy} from 'react';
import {setCookie} from 'nookies';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {ONE_YEAR_COOKIE_MAX_AGE} from 'services/constants';
import constants from 'assets/sass/constants.module.scss';
import routes from 'config/routes';

const CookiesConsentPreferences = lazy(() => import('components/common/CookiesConsentPreferences'));
const ENTRANCE_ANIMATION_DURATION = 500;

const CookieToast = ({closeCookiesToast} : any) => {

    const [hasEntranceAnimation, setHasEntranceAnimation] = useState(true);
    const [isVisibleConsentPreferences, setConsentPreferencesVisibility] = useState(false);

    useEffect(() => {

        const timeout = setTimeout(() => {
            setHasEntranceAnimation(false);
        }, ENTRANCE_ANIMATION_DURATION);

        return () => clearInterval(timeout);

    }, []);

    const acceptCookies = (experienceEnhancement: any) => {

        let consent = {
            experienceEnhancement
        };

        setCookie(null, "consent", JSON.stringify(consent), {maxAge: ONE_YEAR_COOKIE_MAX_AGE, path: '/', secure: true, sameSite: "lax"});
        closeCookiesToast();

    };

    const acceptAllCookies = () => acceptCookies(true);

    const handleReadMore = () => setConsentPreferencesVisibility(true);

    const closeConsentPreferences = () => setConsentPreferencesVisibility(false)

    const cookiesToastClasses = classNames('CookieToast', 'is-flex', 'justify-between', 'align-center',
        {'has-entrance-animation': hasEntranceAnimation}
    );

    return (
        <Fragment>
            
            {isVisibleConsentPreferences && (
                <CookiesConsentPreferences
                    closeCookiesToast={closeCookiesToast}
                    closeDialog={closeConsentPreferences}
                />
            )}

            <div className={cookiesToastClasses}>
                <div className='is-flex align-center m-r-2'>
                    <img 
                        src="/images/icons/cookie.svg" 
                        alt="cookies"
                        width="24"
                        height="24"
                        className='m-r-1'
                    />
                    <div>
                        <p className='has-text-blue m-b-0'>We use cookies to improve your browser experience</p>
                        <p className='is-size-6-half m-b-0'>Find out more about our cookies <Link to={routes.cookiePolicy} className="link-underline">here</Link></p>
                    </div>
                </div>
            
                <div className='is-flex align-center'>
                    <div className='m-r-1 link-underline' onClick={handleReadMore}>Customize</div>
                    <button className='button is-small' onClick={acceptAllCookies}>Accept all</button>
                </div>
                
                <style jsx>{`
                    .CookieToast {
                        position: fixed;
                        bottom: 1rem;
                        left: 50%;
                        transform: translateX(-50%);
                        padding: 1rem;
                        z-index: 100;
                        backdrop-filter: saturate(50%) blur(5px);
                        -webkit-backdrop-filter: saturate(50%) blur(5px);
                        background-color: rgba(255, 255, 255, 0.75);
                        border-radius: ${constants.radiusSmall};
                        animation-fill-mode: forwards;
                        &.has-entrance-animation {
                            animation: slide-from-bottom ${ENTRANCE_ANIMATION_DURATION}ms;
                        }
                        @media all and (max-width: ${constants.tabletMaxW}) {
                            flex-direction: column;
                            width: 87vw;
                            gap: 1rem;
                        }
                        .button {
                            white-space: nowrap;
                        }
                    }
                    @keyframes slide-from-bottom {
                        0% {
                            bottom: -20rem;
                        }
                        100% {
                            bottom: 1rem;
                        }
                    }

                `}</style>

            </div>
        </Fragment>
    )
};

export default CookieToast;