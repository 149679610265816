import {useState} from 'react';
import {Link} from 'react-router-dom';
import classNames from "classnames";
import {isValidEmail} from 'services/utils';
import constants from 'assets/sass/constants.module.scss';
import routes from 'config/routes';

const Footer = () => {

    const [successMessage, setSuccessMessage] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [agree, setAgree] = useState(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [errors, setErrors] = useState<any>({});

    const handleEmailChange = (e: any) => setEmail(e.target.value);

    const handleAgreeChange = (e: any) => setAgree(e.target.checked);

    const clearError = (e: any) => {
        delete errors[e.target.name];
        setErrors({...errors});
    };

    const handleSubscribe = () => {

        const errors = validateData();

        if (Object.keys(errors).length > 0) return setErrors(errors);

        setIsLoadingBtn(true);

        setTimeout(() => {
            setIsLoadingBtn(false);
            setSuccessMessage('You have subscribed successfully');
            setAgree(false);
            setEmail('');
        }, 1000);

    };

    const validateData = () => {

        const errors: any = {};

        if (!email) {
            errors.email = 'E-mail is required.';
        } else if (!isValidEmail(email)) {
            errors.email = 'Enter a valid e-mail.';
        }

        if (!agree) {
            errors.agree = 'Please, accept Privacy Policy.';
        }

        return errors;

    };

    const emailClasses = classNames('input', 'flex-1', 'm-b-0', {
        'is-danger': !!errors.email
    });

    const subscribeBtnClasses = classNames('button', {
        'is-loading': isLoadingBtn
    });

    return (
        <footer className="Footer">
            <div className="container">
                <img 
                    src="/images/logos/logo-white.svg" 
                    alt="logo" 
                    className='logo m-b-2'
                    width="140"
                    height="39"
                />

                <div className="columns is-flex">
                    <div className='flex-3 left-column'>
                        <nav className='is-flex justify-between'>
                            <ul>
                                <li>
                                    <Link to={`${routes.home}#services`}>Services</Link>
                                </li>
                                <li>
                                    <Link to={routes.ourProcess}>Our process</Link>
                                </li>
                                <li>
                                    <Link to={routes.faq}>FAQ</Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to={routes.cookiePolicy}>Cookies policy</Link>
                                </li>
                                <li>
                                    <Link to={routes.privacyPolicy}>Privacy policy</Link>
                                </li>
                                <li>
                                    <Link to={routes.contacts}>Contacts</Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to={routes.manualTesting}>Manual testing</Link>
                                </li>
                                <li>
                                    <Link to={routes.automationTesting}>Automation testing</Link>
                                </li>
                                <li>
                                    <Link to={routes.apiTesting}>API Testing</Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to={routes.webAndMobileQA}>Web and Mobile QA</Link>
                                </li>
                                <li>
                                    <Link to={routes.securityTesting}>Security Testing</Link>
                                </li>
                                <li>
                                    <Link to={routes.performanceTesting}>Performance Testing</Link>
                                </li>
                            </ul>
                        </nav>
                        <p className='reserved m-t-1 m-b-0 is-hidden-touch'>
                            {new Date().getFullYear()} © RealQA | All Rights Reserved
                        </p>
                    </div>
                   
                    <div className="vertical-separator is-hidden-touch"/>
                    
                    <div className='flex-2'>
                        <p className='has-text-weight-bold is-size-5 is-hidden-touch'>
                            The latest news from our company and the best offers for you in our subscriptions
                        </p>
                        <p className='has-text-weight-bold is-size-5 is-hidden-desktop'>
                            The best offers in our subscriptions
                        </p>
                        <div className="field">
                            <label htmlFor='email' className='label'>
                                Your email
                            </label>
                            <div className="is-flex w-100 subscribe-container">
                                <div className='flex-1'>
                                    <input 
                                        type='email' 
                                        id='email' 
                                        name='email'
                                        placeholder='Email'
                                        value={email}
                                        className={emailClasses}
                                        onChange={handleEmailChange} 
                                        onFocus={clearError}
                                    />
                                    {!!errors.email && (
                                        <div className="help has-text-danger">
                                            {errors.email}
                                        </div>
                                    )}
                                    {!!successMessage && (
                                        <div className="help has-text-success">
                                            {successMessage}
                                        </div>
                                    )}
                                </div>
                                <button 
                                    className={subscribeBtnClasses}
                                    onClick={handleSubscribe}
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                        <label className="checkbox is-flex align-center">
                            <input 
                                type="checkbox"
                                onChange={handleAgreeChange}
                                onFocus={clearError}
                                checked={agree}
                                name="agree"
                                className='m-r-half-rem m-t-0'
                            />
                            <span className='has-cursor-pointer is-size-6-half'>
                                By subscribing I accept&nbsp;
                                <Link to={routes.privacyPolicy} target='_blank'>Privacy policy</Link>
                            </span>
                        </label>
                        {!!errors.agree && (
                            <div className="help has-text-danger">
                                {errors.agree}
                            </div>
                        )}
                    </div>
                    <p className='reserved m-b-0 is-hidden-desktop'>
                        {new Date().getFullYear()} © RealQA | All Rights Reserved
                    </p>
                </div>

            </div>

            <style jsx>{`
                .Footer {
                    background-color: ${constants.blue};
                    color: ${constants.white};
                    padding: 1.5rem;
                    .columns {
                        width: 100%;
                        justify-content: space-between;
                        gap: 2.5rem;
                        .left-column {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                        }
                        .subscribe-container {
                            gap: 1rem;
                            @media all and (min-width: ${constants.desktop}) {
                                align-items: center;
                            }
                        }
                        @media all and (max-width: ${constants.tabletMaxW}) {
                            flex-direction: column;
                            nav {
                                justify-content: space-between;
                                flex-wrap: wrap;
                                gap: 1.5rem;
                                ul {
                                    width: calc(50% - 1.5rem);
                                }
                            }
                            .subscribe-container {
                                flex-direction: column;
                                .button {
                                    width: fit-content;
                                }
                            }
                        }
                        .vertical-separator {
                            width: 1px;
                            background-color: ${constants.white};
                        }
                        ul {
                            margin-block: -0.75rem;
                            li {
                                padding-block: 0.5rem;
                            }
                        }
                        a {
                            color: ${constants.white};
                            white-space: nowrap;
                        }
                        input {
                            margin-bottom: 0;
                        }
                    }
                   
                    .reserved {
                        font-size: 0.75rem;
                        @media all and (min-width: ${constants.desktop}) {
                            text-align: right;
                        }
                    }

                }

            `}</style>
        </footer>
    )
};

export default Footer;